import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie'; // Import Lottie from 'react-lottie'
import animationData from '../welcomeAnimation/BV zodiac animation.json'; // Import your JSON animation file
import layer from '../images/layer.svg';
import './intro.css'; // Import the CSS
import poonam from '../images/poonam.png';
import iimK from '../images/iimK.png';
import lsmaf from '../images/lsmaf.png';
import Seminarpopup from './Seminarpopup';
import Webinar from './webinar';
import GoogleAdsConversion from './GoogleAdds';
import ReactGA from 'react-ga'; // Import ReactGA
ReactGA.initialize('AW-11190286711')


const Intro = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isWebinarOpen, setWebinarOpen] = useState(false);
  const [animationComplete, setAnimationComplete] = useState(false);

  // Simulate the animation duration
  useEffect(() => {
    const animationDuration = 2000; // Adjust the duration as needed
    setTimeout(() => {
      setAnimationComplete(true);
    }, animationDuration);

    handleFormSubmission()
  }, []);


  // Define your animation options
  const animationOptions = {
    loop: true, // Set to true if you want the animation to loop
    autoplay: true, // Set to true if you want the animation to play automatically
    animationData: animationData, // Import your animation data
  };


  function handleFormSubmission() {
    // Your form submission logic here
  
    // Trigger the conversion event
    ReactGA.event('event', 'conversion', {
      'send_to': 'AW-11190286711/Bb9zCK2B-ewYEPfy-Ncp', // Use your Conversion ID and Label here.
    });
  }

  return (
    <>
    <div className="intro-container">
      <div className="left-content">
        <h1>Let ancient Vedic science solve <span style={{fontWeight:900}}> your life’s problems with career, love, children</span> and all spectators of your life</h1>
        <h2>Meet Poonam Dutta,</h2>
        <p>World famous astrologer who has helped thousands of her clients with her astrological readings</p>
        <div className="btns-div">
          {/* <button onClick={() => setWebinarOpen(true)}>Join Seminar</button> */}
          <button onClick={() => setModalOpen(true)}>Book Online Consultation</button>
        </div>
      </div>
      <div className="right-content">
        <img src={poonam} alt="poonam-dutta" />
      </div>
    </div>

    <div className="Trusted-By">
      <p>Alumna of:</p>
      <div className="imgs">
        <img className="iimk" src={iimK} alt="iim-kolkata" />
        <img className="lsmaf" src={lsmaf} alt="lsmaf" />
      </div>
    </div>

    {!animationComplete && (
      // Render the Lottie animation with an overlay
      <div className="lottie-overlay">
        <div className="lottie-container">
          <Lottie options={animationOptions} height={300} width={300} />
        </div>
      </div>
    )}

    {isModalOpen && <Seminarpopup closeModal={() => setModalOpen(false)} />}
    {isWebinarOpen && <Webinar closeModal={() => setWebinarOpen(false)} />}
    <GoogleAdsConversion/>
    </>
  );
};

export default Intro;
