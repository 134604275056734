import React, { useEffect, useState } from 'react'
import poonam from '../images/poonam.png';
import './about.css'
import Seminarpopup from './Seminarpopup';
import Webinar from '../components/webinar'
import GoogleAdsConversionTag from './GoogleAdds';

const About = () => {

  const [isModalOpen, setModalOpen] = useState(false)

  const [seminars, setSeminars] = useState(0);
  const [years, setYears] = useState(0);
  const [clients, setClients] = useState(0);
  const [sessionOpen, setsessionOpen] = useState(false);


  useEffect(() => {
    const seminarsInterval = setInterval(() => {
      if (seminars < 500) {
        setSeminars(seminars + 5); // Increment by 5 for faster animation
      } else {
        clearInterval(seminarsInterval);
      }
    }, 10);

    const yearsInterval = setInterval(() => {
      if (years < 10) {
        setYears(years + 1);
      } else {
        clearInterval(yearsInterval);
      }
    }, 10);

    const clientsInterval = setInterval(() => {
      if (clients < 8500) {
        setClients(clients + 50); // Increment by 50 for faster animation
      } else {
        clearInterval(clientsInterval);
      }
    }, 10);

    return () => {
      clearInterval(seminarsInterval);
      clearInterval(yearsInterval);
      clearInterval(clientsInterval);
    };
  }, [seminars, years, clients]);



  return (
    <>
      <div className="about-container">
        <div className="image-container">
        <h2>Why trust in Astrology?</h2>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/FusY2tsUniE?si=9-6Dx8BISQ5Ypn98" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>        </div>
        <div className="text-container">
          <h2>Know Poonam</h2>
          <p>
          Poonam excels in solving life’s problems with career, love, relationship, marriage, children with her
astrological readings
<br/>
<br/>
She is a <span style={{fontWeight:700}}>life coach, astrologer &amp; spiritual energy transformation scientist of global repute</span>. Her core
education is in engineering &amp; finance, but her passion for studying the cosmic influences on our lives
drove her to take up astrology as a profession. She works toward <span style={{fontWeight:700}}>holistic healing methods</span>  that
guide and help people make better life choices. These methods range from astrology, mindfulness
and other modalities like numerology, psychoanalysis, law of attraction,<span style={{fontWeight:700}}> tarot services, vaastu
consultancy,</span> among others. Her expertise of almost <span style={{fontWeight:700}}> 15 years </span>in more than <span style={{fontWeight:700}}>28 techniques of
meditation  &amp; manifestation</span> have helped thousands of individuals tide over tough times and
<span style={{fontWeight:700}}> overcome challenges in their personal lives.</span>
</p>          <div className="action-buttons">
            {/* <button onClick={() => setsessionOpen(true)} className="seminar-btn"> Join Seminar</button> */}

            <button onClick={() => setModalOpen(true)} className="session-btn" >Book Online Consultation</button>
          </div>
        </div>
      </div>

      <div className="stats-container">
        <div className="stat">
          <div className="icon">📅</div>
          <h3>{seminars}+</h3>
          <p>Seminars Conducted</p>
        </div>
        <div className="stat">
          <div className="icon">⏳</div>
          <h3>{years}+ </h3>
          <p>Years Of Experience</p>
        </div>
        <div className="stat">
          <div className="icon">👥</div>
          <h3>{clients}+</h3>
          <p>Happy Clients</p>
        </div>
      </div>

      <div className="venue-details">
        <h2>About the Event</h2>
        <p style={{textAlign:"initial"}}>
        &bull; Having troubles in your marriage? <br/>
          
          &bull; Will your financial condition improve?<br/>
          &bull; Are you on the right track in life?<br/>
          &bull; Is your career in trouble ?<br/><br/>
        </p>
 
        <p className='about-venue-details'>
        Ms. Poonam Dutta, one of India’s most sought-after astrologers, will conduct one on one
        <span style={{fontWeight:700}}> confidential personal online consultations.</span> She will address all kinds of queries and curiosities you
might have about your life and what lies ahead by using <span style={{fontWeight:700}}>ancient Vedic science</span>          <br/>
          <br/>
          Among other aspects, she commands specific expertise on 
          <br/>

          <br/>
    
          &bull; the astrological aspects of <span style={{fontWeight:600}}>darakaraka</span> or what your birth chart says about your marital life <br />
           &bull; the significance of having and knowing your<span style={{fontWeight:600}}> ishta devata</span> or <span style={{fontWeight:600}}>kuldevi</span><br /> 
           &bull; possible remedies you can explore if you are facing <span style={{fontWeight:600}}>job insecurity</span> in your life
           <br />
            </p>
        <div className="event-info">
          <p><span className="icon">📅</span> Sunday, November 26, 2023</p>
          <p><span className="icon">📍</span>Online one on one personalised consultation</p>
          <p><span className="icon">🕙</span>Slots available 11 am onwards. Choose your own individual slot</p>

        </div>
        <p>Hurry Up, Limited Slots Left! Only 10 consultations !!!</p>


        <div className="action-buttons">
          {/* <button onClick={() => setsessionOpen(true)} className="seminar-btn"> Join Seminar</button> */}

          <button onClick={() => setModalOpen(true)} className="session-btn" >Book Online Consultation</button>
        </div>

      </div>

      {isModalOpen && (
        <Seminarpopup closeModal={() => setModalOpen(false)} />
      )}

      {sessionOpen && <Webinar closeModal={() => setsessionOpen(false)} />}

      <GoogleAdsConversionTag />
    </>
  )
}

export default About