import React, { useState, useEffect } from 'react';
import './navbar.css';
import BhavishyawaniLogo from '../images/Bhavishyavani.svg';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  const [seats, setSeats] = useState(0);

  const navigate = useNavigate()

  useEffect(() => {

    const fetchSeats = async () => {
        // Replace this with your actual API call
        const mockSeats = 100; // Mock value
        setSeats(mockSeats);
      };
  
      fetchSeats();




      
    const targetDate = new Date('2023-11-26');
    const interval = setInterval(() => {
      const now = new Date();
      const distance = targetDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setCountdown({ days, hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(interval);

  }, []);

  return (
    <>
      <div className="navbar">
        <img style={{cursor:'pointer'}} onClick={()=>{navigate('/')}} src={BhavishyawaniLogo} alt="Brand Logo" className="brand-logo" />
      </div>
      <div className="countdown-strip">
        <div className="countdown-block">
          {/* <span className="countdown-time">{countdown.days}</span> */}
          <span className="countdown-time">0</span>
          <span className="countdown-label">Days</span>:
          {/* <span className="countdown-time">{countdown.hours}</span> */}
          <span className="countdown-time">0</span>
          <span className="countdown-label">Hours</span>:
          <span className="countdown-time">0</span>
          {/* <span className="countdown-time">{countdown.minutes}</span> */}
          <span className="countdown-label">Minutes</span>:
          <span className="countdown-time">0</span>

          {/* <span className="countdown-time">{countdown.seconds}</span> */}
          <span className="countdown-label">Seconds</span>
        </div>

        <div className='countdown-seats'>
          {/* <span className="seats-number">{seats}</span> */}
          <span className="seats-label">Hurry Up, Limited Slots Left!</span>
        </div>

      </div>
      
    </>
  );
}

export default Navbar;
